<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agency-building-submenu></q-agency-building-submenu>
        <!-- Charts-->
        <div class="mx-5">
            <v-progress-linear color="primary" indeterminate v-if="agent == null"></v-progress-linear>
            <div v-if="agent && typeof agent.AgentID == 'undefined'">
                Whooops! This agent exists but their data is incomplete!
                <q-agent-management-menu :agent="agent" v-on:refresh="reset()"></q-agent-management-menu>
            </div>
            <div v-else-if="agent">
                <v-row class="d-inline-flex mt-2 pb-4">
                    <div>
                        <q-agent-management-menu :agent="agent" v-on:refresh="reset()"></q-agent-management-menu>
                    </div>
                    <div>
                        <v-badge color="blue" :content="agent.ContractLevel">
                            <h5> {{ agent.AgentName }}</h5>
                        </v-badge>
                        <div class="agent-title ">
                            <p>{{ agent.Division }} - {{ agent.LeadershipLevel }} <span v-if="agent.ProducerLevel">- {{agent.ProducerLevel}}</span></p>
                        </div>
                    </div>
                    <div>
                        <v-chip v-if="agent.Status == 'Active'" class=" ml-7" color="primary" text-color="white">{{ agent.Status }}</v-chip>
                        <v-chip v-else class=" ml-7" color="warning" text-color="white">{{ agent.Status }}</v-chip>
                    </div>
                </v-row>
                <QTabs dark>
                    <v-tab @click="logTabActivty('Profile')">Profile</v-tab>
                    <v-tab @click="logTabActivty('Licensing')">Licensing</v-tab>
                    <v-tab v-if="!isDemoMode" @click="logTabActivty('Support')">Support</v-tab>
                    <v-tab @click="logTabActivty('Stats')">Stats</v-tab>
                    <v-tab @click="logTabActivty('Leads')">Leads</v-tab>
                    <v-tab @click="logTabActivty('PendingPolicy')">Policies</v-tab>
                    <v-tab @click="logTabActivty('Commission')">Commission</v-tab>
                    <v-tab @click="logTabActivty('Recruiting')">Recruiting</v-tab>
                    <v-tab @click="logTabActivty('Documents')">Documents</v-tab>
                    <v-tab @click="logTabActivty('Notes')">
                        <v-badge v-if="note_count > 0" color="yellow lighten-4 black--text" :content="note_count">Notes</v-badge>
                        <span v-else>Notes</span>
                    </v-tab>
                    <v-tab @click="logTabActivty('RawData')" v-if="hasRole('SuperAdmin')">Raw Data</v-tab>
                    <v-tab-item class="profile-info">
                        <v-row class="text-left pa-3 cms" wrap>
                            <v-col cols="12" lg="4" class="px-6">
                                <QUserBadges :agent="agent" />
                                <!-- <v-img v-if="agent.CurrentSummitBadge" :src="agent.CurrentSummitBadge.full" class="summit-badge"></v-img>
                                <v-img v-if="['regular', 'golden'].includes(agent.SlingshotBadges)" :src="slingshotBadgeUrl(agent.SlingshotBadges)" class="summit-badge"></v-img> -->
                                <div v-if="agent.Avatar" class="agent_profile_photo">
                                    <v-img :aspect-ratio="1" :src="agent.Avatar"></v-img>
                                </div>
                                <div class="mt-5">
                                    <v-list>
                                        <v-list-item v-if="agent.Status == 'Terminated'">
                                            <v-list-item-icon class="text-center" style="width:30px;">
                                                <v-icon color="q_green_1" style="margin:0 auto;">fas fa-ban</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ agent.Status }} : {{ agent.TerminationReason }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider v-if="agent.Status == 'Terminated'"></v-divider>
                                        <v-list-item>
                                            <v-list-item-icon class="text-center" style="width:30px;">
                                                <v-icon color="q_green_1" style="margin:0 auto;">fas fa-envelope</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <a :href="'mailto:' + agent.AgentEmail">{{ agent.AgentEmail }}</a><br>
                                                    <span v-if="agent.BusinessEmail">
                                                        Business : <a style="padding-right: 4px;" :href="'mailto:' + agent.BusinessEmail">{{ agent.BusinessEmail }}</a>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon color="#999999" dark v-bind="attrs" v-on="on">
                                                                    fas fa-question-circle
                                                                </v-icon>
                                                            </template>
                                                            <span>Business email is for DTC use only.</span>
                                                        </v-tooltip>
                                                    </span>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider></v-divider>
                                        <v-list-item>
                                            <v-list-item-icon class="text-center" style="width:30px;">
                                                <v-icon color="q_green_1" style="margin:0 auto;">fas fa-phone</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <a :href="'tel:' + agent.AgentPhone">{{ agent.AgentPhone }}</a><br>
                                                    <span v-if="agent.BusinessPhone">
                                                        Business : <a style="padding-right: 4px;" :href="'tel:' + agent.BusinessPhone">{{ agent.BusinessPhone }}</a>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon color="#999999" dark v-bind="attrs" v-on="on">
                                                                    fas fa-question-circle
                                                                </v-icon>
                                                            </template>
                                                            <span>Business phone is for DTC use only.</span>
                                                        </v-tooltip>
                                                        <br>
                                                    </span>
                                                    <span v-if="agent.SummitPhone">
                                                        Summit : <a style="padding-right: 4px;" :href="'tel:' + agent.SummitPhone">{{ agent.SummitPhone }}</a>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon color="#999999" dark v-bind="attrs" v-on="on">
                                                                    fas fa-question-circle
                                                                </v-icon>
                                                            </template>
                                                            <span>Summit phone is for Summit notifications use only.</span>
                                                        </v-tooltip>
                                                        <br>
                                                    </span>
                                                    <span v-if="agent.CellPhone">Cell : <a :href="'tel:' + agent.CellPhone">{{ agent.CellPhone }}</a></span>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider></v-divider>
                                        <v-list-item>
                                            <v-list-item-icon class="text-center" style="width:30px;">
                                                <v-icon color="q_green_1" style="margin:0 auto;">fas fa-address-card</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ agent.StreetAddress }}<br>
                                                    {{ agent.City }}, {{ agent.State }} {{ agent.Zip }}<br>
                                                    <span v-if="agent.County">County : {{agent.County}}</span>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider></v-divider>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon color="q_green_1" style="margin:0 auto;">fas fa-calendar-check</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <div class="d-flex" style="gap: 1rem">
                                                    <div>
                                                        <v-list-item-title v-if="agent.LastLoginDate" class="">
                                                            <div style="display:inline-block; width:150px; "><b>Last HQ Login:</b></div>
                                                            <div style="display:inline-block; width:150px; ">{{ formatDateCalendarWithYear(agent.LastLoginDate) }}</div>
                                                        </v-list-item-title>
                                                        <v-list-item-title v-if="agent.LastActivityDate" class="pt-2">
                                                            <div style="display:inline-block; width:150px; "><b>Last Opt! Login:</b></div>
                                                            <div style="display:inline-block; width:150px; "> {{ formatDateCalendarWithYear(agent.LastActivityDate) }}</div>
                                                        </v-list-item-title>
                                                        <v-list-item-title v-if="agent.ContractStartDt" class="">
                                                            <div style="display:inline-block; width:150px; "><b>Start Date:</b></div>
                                                            <div style="display:inline-block; width:150px; ">{{ formatDateCalendarWithYear(agent.ContractStartDt) }}</div>
                                                        </v-list-item-title>
                                                        <v-list-item-title v-if="agent.FastTrack && agent.FastTrack.lightSpeedFastTrackCompleteDate">
                                                            <div style="display:inline-block; width:150px; "><b>FastTrack Completion Date:</b></div>
                                                            <div style="display:inline-block; width:150px; ">{{ formatDateCalendarWithYear(agent.FastTrack.lightSpeedFastTrackCompleteDate) }}</div>
                                                        </v-list-item-title>
                                                    </div>
                                                    <div v-if="agent.Blocked && !wasUnblocked" class="d-flex align-start flex-wrap" style="gap: 0.5rem 2rem;">
                                                        <div>
                                                            <b class="nowrap" style="font-size: 1rem;">Blocked</b>
                                                        </div>
                                                        <div v-if="hasPermission(['manage:Auth0'])">
                                                            <v-btn @click="doShowUnblockModal = true">Unblock</v-btn>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-icon class="text-center" style="width:30px;">
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>
													<v-row>
													<v-col cols="6">
														<v-select label="Email Type" v-model="resendEmailType" :items="onboardingEmails"></v-select>
													</v-col>
													<v-col cols="6">
														<v-btn class="mt-3" @click="sendWelcomeEmail">Resend Email</v-btn>
													</v-col>
													</v-row>
                                                    <br>
                                                    <v-btn class="mt-4 mb-4" @click="resetPassword">Reset Password to Default</v-btn>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </v-col>
                            <v-col cols="12" lg="4" class="px-6">
                                <v-alert type="error" v-if="agent.IsLocked">
                                    <img src="https://hq-image-cdn.azureedge.net/servers/production/cms_images/yoda_YjKpNK.png" width="100" style="float: left" />
                                    Patience young Skywalker... This agent is currently <strong>locked</strong> because another process is modifying it. This agent will be unlocked after {{ agent.LockExpirationTime }} minutes.
                                </v-alert>
                                <v-alert type="warning" v-if="!agent.IsFullyLicensed">
                                    This agent is not fully licensed!
                                </v-alert>
                                <v-alert type="warning" v-if="agent.Flanking && agent.Flanking.length" border="top" colored-border elevation="2">
                                    <div class="overline mb-4">This agent is being flanked by</div>
                                    <div v-for="flanking in agent.Flanking" :key="flanking.AgentID" @click="clickAgent(flanking.AgentCode)" style="cursor:pointer;">
                                        {{flanking.AgentName}}
                                        <span v-if="flanking.FlankExpDate != null">
                                            - {{formatReadableDate(flanking.FlankExpDate)}} ({{relativeDate(flanking.FlankExpDate)}})
                                        </span>
                                    </div>
                                </v-alert>
                                <v-list style="">
                                    <v-list-item>
                                        <v-list-item-icon class="text-center" style="width:30px;">
                                            <v-icon color="q_green_1" style="margin:0 auto;">fas fa-user</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <div class="overline">Agent Code:</div>
                                                <div>{{ agent.AgentCode }} </div>
                                                <div class="overline" style="padding-top:1em;">Agent NPN#: </div>
                                                <div>{{ agent.NPN }}</div>
                                                <div class="overline" style="padding-top:1em;">Opt ID:</div>
                                                <div>{{ agent.OptID }}</div>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-list-item>
                                        <v-list-item-icon class="text-center" style="width:30px;">
                                            <v-icon style="margin:0 auto;" color="q_green_1">fas fa-dollar-sign</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <div class="overline text-wrap" style="display:inline-block:">Account Balance:</div>
                                                <div class="pa-1" style="display:inline-block:" v-if="agent.AgentAccount != null">
                                                    {{ formatCurrency(agent.AgentAccount.Balance) }}
                                                    <v-btn class="ml-5" small :to="'/agents/' + agent.AgentCode + '/account_balance'">View Ledger</v-btn>
                                                </div>
                                                <div style=" display:inline-block:" v-else>
                                                    No current payment method on file.
                                                </div>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-list-item>
                                        <v-list-item-icon class="text-center" style="width:30px;">
                                            <v-icon style="margin:0 auto;" color="q_green_1">fas fa-crown</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <div class="overline text-wrap" style="display:inline-block:">Leadership Level:</div>
                                                <div style="display:inline-block:"> {{ agent.LeadershipLevel }}<span v-if="agent.ProducerLevel">, {{agent.ProducerLevel}}</span></div>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <div v-if="!agent.HideDBA">
                                        <v-divider v-if="agent.DoingBusinessAsName || agent.DoingBusinessAsNPN"></v-divider>
                                        <v-list-item v-if="agent.DoingBusinessAsName || agent.DoingBusinessAsNPN">
                                            <v-list-item-icon class="text-center" style="width:30px;">
                                                <v-icon color="q_green_1" style="margin:0 auto;">fas fa-briefcase</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <div v-if="agent.DoingBusinessAsName" class="overline">DBA: </div>
                                                    <div>{{ agent.DoingBusinessAsName }}</div>
                                                    <div v-if="agent.DoingBusinessAsNPN" class="overline" style="padding-top:1em;">DBA NPN #: </div>
                                                    <div>{{ agent.DoingBusinessAsNPN }}</div>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </div>
                                    <div v-else>
                                        <v-divider v-if="agent.DoingBusinessAsName || agent.DoingBusinessAsNPN"></v-divider>
                                        <v-list-item v-if="agent.DoingBusinessAsName || agent.DoingBusinessAsNPN">
                                            <v-list-item-icon class="text-center" style="width:30px;">
                                                <v-icon color="q_green_1" style="margin:0 auto;">fas fa-briefcase</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <div>DBA information is hidden</div>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </div>
                                    <v-divider></v-divider>
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon color="q_green_1" style="margin:0 auto;">fas fa-users</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="overline">Memberships and subscriptions</v-list-item-title>
                                            <v-list-item-subtitle>
                                                <v-list dense>
                                                    <v-list-item v-if="agent.IsAdvisoryBoardMember" class="body-2">
                                                        <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                        <v-list-item-content class="pl-3">
                                                            Advisory Board Member
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item v-if="agent.IsOwnerCircleMember" class="body-2 ">
                                                        <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                        <v-list-item-content class="pl-3">Owner's Circle Member</v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item v-if="agent.HasSherpaInd" class="body-2 ">
                                                        <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                        <v-list-item-content class="pl-3">Sherpa</v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item v-if="agent.HasManagerAccess" class="body-2">
                                                        <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                        <v-list-item-content class="pl-3">Virtual Assistant</v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item v-if="agent.PCSParticipant" class="body-2 pt-2">
                                                        <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                        <v-list-item-content class="pl-3">PCS Participant <br />
                                                            Start Date: {{ agent.PCSStartDate }}</v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item v-if="agent.PCSAgentInd" class="body-2 pt-2">
                                                        <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                        <v-list-item-content class="pl-3">PCS Agent<br />
                                                            {{ agent.PCSAgentStartDate }} - {{ agent.PCSAgentEndDate }}</v-list-item-content>
                                                    </v-list-item>
													<v-list-item v-if="agent.DFLCertifiedInd" class="body-2 pt-2">
														<v-icon color="q_green_1">far fa-check-circle</v-icon>
														<v-list-item-content class="pl-3">DFL Certified</v-list-item-content>
													</v-list-item>
													<v-list-item v-if="agent.AnnuityCertifiedInd" class="body-2 pt-2">
														<v-icon color="q_green_1">far fa-check-circle</v-icon>
														<v-list-item-content class="pl-3">Annuity Certified</v-list-item-content>
													</v-list-item>
													<v-list-item v-if="agent.IULCertifiedInd" class="body-2 pt-2">
														<v-icon color="q_green_1">far fa-check-circle</v-icon>
														<v-list-item-content class="pl-3">IUL Certified</v-list-item-content>
													</v-list-item>
													<v-list-item v-if="agent.NavigatorAccess" class="body-2 pt-2">
														<v-icon color="q_green_1">far fa-check-circle</v-icon>
														<v-list-item-content class="pl-3">Navigator Course Certification</v-list-item-content>
													</v-list-item>
                                                    <v-list-item v-if="agent.PCSOnHoldInd" class="body-2 pt-2">
                                                        <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                        <v-list-item-content class="pl-3">PCS On Hold</v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item v-if="agent.SeasonedNewAgentInd" class="body-2 pt-2">
                                                        <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                        <v-list-item-content class="pl-3">Seasoned New Agent</v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item v-if="agent.QuilityRXGroupNumber" class="body-2 pt-2">
                                                        <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                        <v-list-item-content class="pl-3">QuilityRx Agent <br />
                                                            GroupCode: {{agent.QuilityRXGroupNumber}}<br>
                                                            Start Date: {{ formatDate(agent.QuilityRXGroupDate) }}</v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item v-if="agent.SwitchboardConfig != null" class="body-2 pt-2">
                                                        <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                        <v-list-item-content class="pl-3">Switchboard Connected<br />
                                                            LocationID: {{agent.SwitchboardConfig.location_id}}
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item v-if="agent.CurrentSummitStep" class="body-2 pt-2">
                                                        <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                        <v-list-item-content class="pl-3">
                                                            Summit <br> {{agent.CurrentSummitStep.StepCategory}} : {{ ordinalSummitStep }} - {{ agent.CurrentSummitStep.StepTitle }}
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item v-if="appsIndicator.value" class="body-2 pt-2">
                                                        <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                        <v-list-item-content class="pl-3">
                                                            {{ appsIndicator.label }}<br />
                                                            Earn Date: {{ formatDate(appsIndicator.dateEarned) }}
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                            <v-col cols="12" lg="4" class="px-6">
                                <q-agent-upline :agent="agent"></q-agent-upline>
                            </v-col>
                            <v-col cols="12">
                                <q-agent-sticky-notes v-on:notes="updateNoteCount" :agent="agent"></q-agent-sticky-notes>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item class="licensing-info">
                        <div class="pa-4">
                            <h2>Licensing</h2>
                            <q-agent-licensing :agent.sync="agent"></q-agent-licensing>
                        </div>
                    </v-tab-item>
                    <v-tab-item v-if="!isDemoMode" class="licensing-info">
                        <div class="pa-4">
                            <q-agent-support-tickets-data-table :agent="agent" title="Support Tickets"></q-agent-support-tickets-data-table>
                        </div>
                    </v-tab-item>
                    <v-tab-item class="stats">
                        <QTabs class="q-text-left" background-color="q_symm_blue" dark>
                            <v-tab>
                                Production Scorecard
                            </v-tab>
                            <v-tab>
                                Recruiting Scorecard
                            </v-tab>
                            <v-tab>
                                Leads Scorecard
                            </v-tab>
                            <v-tab>
                                Events Scorecard
                            </v-tab>
                            <v-tab>
                                QuilityRx Scorecard
                            </v-tab>
                            <v-tab>
                                HQ Scorecard
                            </v-tab>
                            <v-tab-item class="pa-2">
                                <score-cards-new-business :show-submenu="false" :allowAgentLookup="false"></score-cards-new-business>
                            </v-tab-item>
                            <v-tab-item class="pa-2">
                                <score-cards-recruiting :show-submenu="false" :allowAgentLookup="false"></score-cards-recruiting>
                            </v-tab-item>
                            <v-tab-item class="pa-2">
                                <score-cards-leads :show-submenu="false" :allowAgentLookup="false"></score-cards-leads>
                            </v-tab-item>
                            <v-tab-item class="pa-2">
                                <score-cards-events :show-submenu="false" :allowAgentLookup="false"></score-cards-events>
                            </v-tab-item>
                            <v-tab-item class="pa-2">
                                <score-card-quility-rx :show-submenu="false" :allowAgentLookup="false"></score-card-quility-rx>
                            </v-tab-item>
                            <v-tab-item class="pa-2">
                                <score-cards-hq :show-submenu="false" :allowAgentLookup="false"></score-cards-hq>
                            </v-tab-item>
                        </QTabs>
                        <!--<q-downline-stats :agent="agent" v-if="agent && agent.IsAgencyOwner"></q-downline-stats>//-->
                    </v-tab-item>
                    <v-tab-item class="leads">
                        <QTabs class="q-text-left" background-color="q_symm_blue" dark>
                            <v-tab>
                                Leads
                            </v-tab>
                            <v-tab>
                                Lighthouse Lead Orders
                            </v-tab>
							<v-tab v-if="hasPermission('agent:EconomyLeadOrders')">
                                Economy A Lead Orders
                            </v-tab>
                            <v-tab>
                                Recruiting Lead Orders
                            </v-tab>
                            <v-tab>
                                Call-in Lead Orders
                            </v-tab>
                            <v-tab v-if="agent.IsAgencyOwner">
                                Baseshop Lead Orders
                            </v-tab>
                            <v-tab>
                                Allocation Log
                            </v-tab>
                            <v-tab-item class="pa-2">
                                <q-lead-data-table :agent="agent" :rows="10" title="Leads"></q-lead-data-table>
                            </v-tab-item>
                            <v-tab-item class="pa-2">
                                <q-digital-lead-order-data-table :no-actions="true" :lead-types="['Lighthouse']" ref="detail_lighthouse_lead_orders" :agent-code="agent.AgentCode" title="Lighthouse" key="agent_detail_lighthouse_lead_orders"></q-digital-lead-order-data-table>
                            </v-tab-item>
							<v-tab-item v-if="hasPermission('agent:EconomyLeadOrders')" class="pa-2">
                                <q-digital-lead-order-data-table :no-actions="true" :lead-types="['Economy']"  :agent-code="agent.AgentCode" title="Digital Economy Leads"></q-digital-lead-order-data-table>
                            </v-tab-item>
                            <v-tab-item class="pa-2">
                                <q-dx-lead-order-data-table :no-actions="true" :lead-types="['Recruiting']" ref="recruiting_lead_order_datatable" :agent-code="agent.AgentCode" title="Recruiting Lead Orders" key="agent_detail_recruiting_lead_orders"></q-dx-lead-order-data-table>
                            </v-tab-item>
                            <v-tab-item class="pa-2">
                                <q-digital-lead-order-data-table :no-actions="true" :lead-types="['Call In']" ref="call_in_lead_orders" :agent-code="agent.AgentCode" title="Call-In" key="agent_call_in_lead_orders"></q-digital-lead-order-data-table>
                            </v-tab-item>
                            <v-tab-item class="pa-2" v-if="agent.IsAgencyOwner">
                                <q-baseshop-lead-order-report-table title="Baseshop Lead Orders" :agent-code="agent.AgentCode"></q-baseshop-lead-order-report-table>
                            </v-tab-item>
                            <v-tab-item class="pa-2">
                                <q-lead-allocation-data-table :no-actions="true" ref="allocation_datatable" :agent-code="agent.AgentCode" title="Lead Allocation" key="agent_detail_allocation"></q-lead-allocation-data-table>
                            </v-tab-item>
                        </QTabs>
                    </v-tab-item>
                    <v-tab-item class="pending_policies">
                        <QTabs class="q-text-left" background-color="q_symm_blue" dark>
                            <v-tab>
                                Pending Policies
                            </v-tab>
                            <v-tab>
                                Placed Policies
                            </v-tab>
                            <v-tab>
                                Conserved Policies
                            </v-tab>
                            <v-tab>
                                Applications
                            </v-tab>
                            <v-tab-item class="pa-2">
                                <q-pending-policy-data-table :agent="agent" :rows="25" title="Pending Policies"></q-pending-policy-data-table>
                                <!-- <q-pending-policy-data-table :for_baseshop="1" :agent="agent" :rows="25" title="Pending Policies"></q-pending-policy-data-table> -->
                            </v-tab-item>
                            <v-tab-item>
                                <q-placed-policy-data-table :agent="agent" :rows="25" title="Placed Policies"></q-placed-policy-data-table>
                            </v-tab-item>
                            <v-tab-item>
                                <q-conserved-policies-drilldown :agent-code="agent.AgentCode" :rows="25" title="Conserved Policies"></q-conserved-policies-drilldown>
                            </v-tab-item>
                            <v-tab-item>
                                <q-applications-table :agent-code="agent.AgentCode" :rows="25" title="Applications"></q-applications-table>
                            </v-tab-item>
                        </QTabs>
                    </v-tab-item>
                    <v-tab-item class="commission">
						<BonusReport :agent="agent"/>
                    </v-tab-item>
                    <v-tab-item class="recruiting">
                        <QTabs class="q-text-left" background-color="q_symm_blue" dark>
                            <!-- <v-tab>Recruiting Dashboard</v-tab> -->
                            <v-tab>
                                <span v-if="agent.LeadershipLevel != 'Agency Owner'">Total Agency Directory</span>
                                <span v-else>Base Shop Directory</span>
                            </v-tab>
                            <v-tab>New Agent Stats</v-tab>
                            <v-tab>Onboarding Stats</v-tab>
                            <v-tab>Hierarchy</v-tab>
                            <!-- <v-tab-item class="pa-2">
                                <AoRecruitingDashboard :agent-code="agent.AgentCode"></AoRecruitingDashboard>
                            </v-tab-item> -->
                            <v-tab-item class="pa-2">
                                <q-agent-downline-directory-table :rows="20" :show-all="true" :title="agent.AgentName + ' Agency'" :agent="agent"></q-agent-downline-directory-table>
                            </v-tab-item>
                            <v-tab-item class="pa-2">
                                <q-agent-recruiting-stats-new-agent :agent="agent"></q-agent-recruiting-stats-new-agent>
                            </v-tab-item>
                            <v-tab-item class="pa-2">
                                <h3>Onboarding Stats Coming Soon!</h3>
                            </v-tab-item>
                            <v-tab-item class="pa-2">
                                <q-agency-structure-chart :agent="agent"></q-agency-structure-chart>
                            </v-tab-item>
                        </QTabs>
                        <div class="pa-4">
                        </div>
                    </v-tab-item>
                    <v-tab-item class="documents">
                        <div class="pa-4">
                            <q-agent-documents :agent="agent"></q-agent-documents>
                        </div>
                    </v-tab-item>
                    <v-tab-item class="licensing-info">
                        <div class="pa-4">
                            <q-agent-notes :agent="agent"></q-agent-notes>
                        </div>
                    </v-tab-item>
                    <v-tab-item v-if="hasRole('SuperAdmin')" class="raw-data">
                        <v-row wrap>
                            <v-col cols="3" v-for="(attr, key) in agent" :key="key">
                                <strong>{{ key }} :<br> </strong> {{attr}}
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </QTabs>
            </div>
        </div>

        <v-dialog v-model="doShowUnblockModal" width="500">
            <v-card>
                <v-card-title>
                    <h4 class="q-display-4 text-center">Are you sure you want to unblock this user?</h4>
                </v-card-title>
                <div class="d-flex justify-center pa-4" style="gap: 1rem;">
                    <v-btn :disabled="saving" @click="doShowUnblockModal = false">Cancel</v-btn>
                    <v-btn :disabled="saving" color="primary" @click="unblock()">Unblock</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import QAgencyStructureChart from '@/components/charts/QAgencyStructureChart.vue'
import QAgencyBuildingSubmenu from '@/components/navigation/Submenus/QAgencyBuildingSubmenu.vue';
import QDownlineStats from '@/components/stats/QDownlineStats.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import Agent from '@/store/Models/Agent'
import QAgentStats from '@/components/stats/QAgentStats.vue';
import QBaseshopStats from '@/components/stats/QBaseshopStats.vue';
import QAgentUpline from '@/components/lists/QAgentUpline.vue';
import QPersonalProductionStats from '@/components/stats/QPersonalProductionStats.vue';
import QAgentDirectoryTable from '@/components/datatables/QAgentDirectoryTable.vue';
import QAgentDownlineDirectoryTable from '@/components/datatables/QAgentDownlineDirectoryTable.vue';
import QAgentManagementMenu from '@/components/navigation/QAgentManagementMenu.vue';
import QLeaderboardDataTableWrapper from '@/components/datatables/QLeaderboardDataTableWrapper.vue'
import QAgentDocuments from '@/components/agent_admin/QAgentDocuments.vue'
import QAgentSupportTicketsDataTable from '@/components/datatables/QAgentSupportTicketsDataTable.vue'
import QAgentNotes from '@/components/agent_admin/QAgentNotes.vue'
import QAgentStickyNotes from '@/components/agent_admin/QAgentStickyNotes.vue'
import QAgentRecruitingStatsNewAgent from '@/components/stats/QAgentRecruitingStatsNewAgent.vue';
import QLeadDataTable from '@/components/datatables/QLeadDataTable.vue';
import QPendingPolicyDataTable from '@/components/datatables/QPendingPolicyDataTable.vue';
import QPlacedPolicyDataTable from '@/components/datatables/QPlacedPolicyDataTable.vue';
import BonusReport from '@/modules/BonusReport/views/BonusReport.vue'
import ScoreCardsNewBusiness from '@/views/MyStats/ScoreCards_NewBusiness.vue'
import ScoreCardsLeads from '@/views/MyStats/ScoreCards_Leads.vue'
import ScoreCardsRecruiting from '@/views/MyStats/ScoreCards_Recruiting.vue'
import ScoreCardsEvents from '@/components/stats/scorecards/QScoreCards_EventsAll.vue'
import ScoreCardQuilityRx from '@/views/MyStats/QScoreCards_QuilityRx.vue';
import ScoreCardsHq from '@/views/MyStats/ScoreCards_HQ.vue'
import QDxLeadOrderDataTable from '@/components/datatables/LeadOrders/QDxLeadOrderDataTable.vue'
import QDigitalLeadOrderDataTable from '@/components/datatables/LeadOrders/QDigitalLeadOrderDataTable.vue'
import QLeadAllocationDataTable from '@/components/datatables/LeadOrders/QLeadAllocationDataTable.vue'
import QTabs from '../../../components/navigation/QTabs.vue'
import QUserBadges from '@/components/profile/QUserBadges.vue'
import QAgentLicensing from "@/components/utils/QAgentLicensing.vue"
import QConservedPoliciesDrilldown from '@/components/stats/scorecards/QConservedPoliciesDrilldown'
import QApplicationsTable from '@/components/datatables/QApplicationsTable'
import QBaseshopLeadOrderReportTable from '@/components/datatables/LeadOrders/QBaseshopLeadOrderReportTable.vue';
import AoRecruitingDashboard from '@/modules/AoRecruitingDashboard/views/AoRecruitingDashboard.vue';

export default {
    name: "AgentDetail-SuperAdmin",
    data() {
        return {
            agentcode: null,
            agentdownline: {},
            current_agent: null,
            stickyTop: 0,
            date_period: 'monthly',
            editUpline: false,
            note_count: 0,

			resendEmailType: 'Licensed',
			onboardingEmails: [
				'Converted',
				'Licensed',
				'Reactivated',
				'Unlicensed'
			],

            doShowUnblockModal: false,
            wasUnblocked: false,
            saving: false,
        };
    },
    mounted: function() {
        this.reset();
    },
    computed: {
        agent: {
            get() {
                return this.$store.state.entities.agent.currentAgent;
            },
            set(v) {
                Agent.commit((state) => {
                    state.loading = false
                    state.currentAgent = v
                })
            }
        },
        loading: function() {
            return this.$store.state.entities.agent.loading;
        },
        ordinalSummitStep: function() {
            const english_ordinal_rules = new Intl.PluralRules("en", { type: "ordinal" })
            const suffixes = {
                one: "st",
                two: "nd",
                few: "rd",
                other: "th"
            }
            const currentStep = parseInt(this.agent.CurrentSummitStep.StepCategoryOrder) + 1
            const suffix = suffixes[english_ordinal_rules.select(currentStep)]

            return `${currentStep}${suffix}`
        },
        appsIndicator() {
            if (this.agent.AgentFlags?.Twenty_Five_Apps?.value) {
                return {
                    ...this.agent.AgentFlags.Twenty_Five_Apps,
                    label: 'Twenty Five Apps',
                };
            } else if (this.agent.AgentFlags?.Fifteen_Apps?.value) {
                return {
                    ...this.agent.AgentFlags.Fifteen_Apps,
                    label: 'Fifteen Apps',
                };
            }
            return {
                ...this.agent.AgentFlags?.Six_Apps,
                label: 'Six Apps',
            };
        },
    },
    methods: {
        showAgent(agent) {
            this.current_agent = agent
        },
        reset() {
            this.agentcode = this.$route.params.agentcode;
            this.note_count = 0;
            const attrs = ['LeadershipLevel', 'Signature', 'LastLoginDate', 'CurrentSummitBadge', 'QuilityRXGroupNumber', 'HideDBA', 'FastTrack', 'CurrentSummitStep', 'Flanking', 'AgentAccount', 'IsLocked', 'FirstCommissionAccountingCycle',
            'NavigatorAccess', 'AgentFlags', 'PCSOwner', 'Blocked']

            QuilityAPI.getAgent(this.agentcode, attrs).catch((json) => {
                if (json.errors == "Page not found") {
                    Agent.commit((state) => {
                        state.loading = false
                        state.currentAgent = {
                            AgentCode: this.agentcode
                        }
                    })
                }
            });
        },
        getDownline: function() {
            return this.recursiveDownline(this.agent.Downline)
        },
        getAgentDownline: function(AgentCode) {
            var g = this
            QuilityAPI.getAgentDownline(AgentCode, [], function(json) {
                g.agentdownline[AgentCode] = json
            });
        },
        recursiveDownline: function(children) {
            var str = ""
            for (var i = 0; i < children.length; i++) {
                str = str + "<a href='/admin/agents/" + children[i].AgentCode + "'>" + children[i].AgentName + "</a><br>";
                if (children[i].Downline == null) continue;
                if (children[i].Downline.length > 0) {
                    str = str + this.recursiveDownline(children[i].Downline);
                }
            }
            return str;
        },
        loadAgent(agentcode) {
            this.$router.push('/admin/agents/' + agentcode)
        },
        updateNoteCount(notes) {
            this.note_count = notes.meta.note_count;
        },
        clickAgent: function(ac) {
            this.$router.push('/agents/' + ac)
        },
        sendWelcomeEmail: function() {
            var g = this
            QuilityAPI.postRequest('/api/private/base_agent/' + this.$route.params.agentcode + '/send_welcome_email', {
				type: this.resendEmailType
			}).then(function(json) {
                g.showSuccess("Email Sent!");
            })
        },
        resetPassword: function() {
            var g = this
            QuilityAPI.postRequest('/api/private/base_agent/' + this.$route.params.agentcode + '/reset_password').then(function(json) {
                g.showSuccess("Password Reset to " + json.data[0].password + "");
            })
        },
        unblock() {
            this.saving = true
            QuilityAPI.postRequest('/api/private/auth0/' + this.$route.params.agentcode + '/block-unblock', {
                unblock: 1,
            })
                .then((json) => {
                    if (json.success) {
                        this.wasUnblocked = true
                        this.showSuccess("Agent unblocked successfully.");
                    } else {
                        this.showError("Agent cannot be unblocked due to their current status in HQ.");
                    }
                })
                .catch((error) => {
					if (error.isDemoMode) {
						this.showError(error.msg)
						return
					}
                    this.showError("Agent cannot be unblocked due to their current status in HQ.");
                })
                .finally(() => {
                    this.doShowUnblockModal = false
                    this.saving = false
                })
        },
    },
    watch: {
        '$route.params.agentcode': function(newV, oldV) {
            if (typeof newV != 'undefined' && this.agentcode != newV) {
                this.reset();
            }
        },
        '$store.state.entities.agent.currentAgent.NeedsUpdate': function(newV) {
            if (newV === true) {
                this.reset();
            }
        },
        agent() {
            this.setPageTitle((this.agent && this.agent.AgentName) || 'Agent Detail')
        },
    },
    components: {
        QAgentLicensing,
        QAgencyBuildingSubmenu,
        QAgentStats,
        QDownlineStats,
        QPersonalProductionStats,
        QBaseshopStats,
        QAgentUpline,
        QAgencyStructureChart,
        QAgentDirectoryTable,
        QAgentDownlineDirectoryTable,
        QAgentManagementMenu,
        QLeaderboardDataTableWrapper,
        QAgentDocuments,
        QAgentSupportTicketsDataTable,
        QAgentNotes,
        QAgentStickyNotes,
        QAgentRecruitingStatsNewAgent,
        QLeadDataTable,
        QPendingPolicyDataTable,
        QPlacedPolicyDataTable,
		BonusReport,
        ScoreCardsNewBusiness,
        ScoreCardsLeads,
        ScoreCardsRecruiting,
        ScoreCardsEvents,
        ScoreCardQuilityRx,
        QDxLeadOrderDataTable,
        QDigitalLeadOrderDataTable,
        QLeadAllocationDataTable,
        ScoreCardsHq,
        QTabs,
        QUserBadges,
		QConservedPoliciesDrilldown,
		QApplicationsTable,
        QBaseshopLeadOrderReportTable,
        AoRecruitingDashboard
    }
};
</script>
<style scoped>
blockquote {
    margin-left: 50px;
}
</style>