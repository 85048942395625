import { render, staticRenderFns } from "./AgentDetail.vue?vue&type=template&id=84698dd6&scoped=true&"
import script from "./AgentDetail.vue?vue&type=script&lang=js&"
export * from "./AgentDetail.vue?vue&type=script&lang=js&"
import style0 from "./AgentDetail.vue?vue&type=style&index=0&id=84698dd6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84698dd6",
  null
  
)

export default component.exports